import ClearIcon from "@mui/icons-material/Clear";
import { red } from "@mui/material/colors";
import IconButton from "@mui/material/IconButton";
import { useTheme } from "@mui/material/styles";
import Tooltip from "@mui/material/Tooltip";
import React from "react";
import { clearData, useAppDispatch } from "../../features";
import AlertDialog from "../AlertDialog";

export default function ClearDataButton() {
  const theme = useTheme();

  const dispatch = useAppDispatch();

  const [open, setOpen] = React.useState(false);

  const handleClick = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleSubmit = () => {
    setOpen(false);
    dispatch(clearData());
  };

  return (
    <>
      <Tooltip
        title="Clear"
        aria-label="clear"
        placement="bottom"
        disableFocusListener={true}
      >
        <IconButton
          sx={{
            margin: theme.spacing(1)
          }}
          aria-label="new"
          onClick={handleClick}
          size="large"
        >
          <ClearIcon
            sx={{
              color: red[400]
            }}
          />
        </IconButton>
      </Tooltip>
      <AlertDialog
        question="Discard the current data?"
        explanation="The current data will be lost so be sure that you have exported it before clearing it."
        open={open}
        handleClose={handleClose}
        handleSubmit={handleSubmit}
      />
    </>
  );
}
