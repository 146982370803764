import * as React from "react";

export interface CustomEventMap {
  importdata: CustomEvent;
  renameseries: CustomEvent;
  renameset: CustomEvent;
  savedata: CustomEvent;
  setthememode: CustomEvent;
}

interface WindowEventMap
  extends HTMLElementEventMap,
    WindowEventHandlersEventMap,
    CustomEventMap {}

export default function useWindowEventListener<K extends keyof WindowEventMap>(
  type: K,
  callback: (e: WindowEventMap[K]) => void
) {
  React.useEffect(() => {
    window.addEventListener(type, callback);
    return () => {
      window.removeEventListener(type, callback);
    };
  });
}
