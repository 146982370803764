import { SnackbarKey, useSnackbar } from "notistack";
import React from "react";
import { useSelector } from "react-redux";
import {
  Notification,
  removeSnackbar,
  selectNotifications,
  useAppDispatch
} from "../features";

export default function Notifier() {
  const displayed = React.useRef<SnackbarKey[]>([]);

  const dispatch = useAppDispatch();
  const notifications = useSelector(selectNotifications);
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();

  const storeDisplayed = (key: SnackbarKey) => {
    displayed.current.push(key);
  };

  const removeDisplayed = (key: SnackbarKey) => {
    const index = displayed.current.indexOf(key);
    if (index >= 0) {
      displayed.current.splice(index, 1);
    }
  };

  React.useEffect(() => {
    notifications.forEach(
      ({ key, message, dismissed = false, options = {} }: Notification) => {
        if (dismissed) {
          // dismiss snackbar using notistack
          closeSnackbar(key);
          return;
        }

        // do nothing if snackbar is already displayed
        if (displayed.current.includes(key)) {
          return;
        }

        // display snackbar using notistack
        enqueueSnackbar(message, {
          key,
          ...options,
          onClose: (event, reason, myKey) => {
            if (options.onClose) {
              options.onClose(event, reason, myKey);
            }
          },
          onExited: (event, myKey) => {
            // remove this snackbar from redux store
            dispatch(removeSnackbar(myKey));
            removeDisplayed(myKey);
          }
        });

        // keep track of snackbars that we've displayed
        storeDisplayed(key);
      }
    );
  }, [notifications, closeSnackbar, enqueueSnackbar, dispatch]);

  return null;
}
