import Paper from "@mui/material/Paper";
import Typography from "@mui/material/Typography";
import React from "react";

export default function NoData() {
  return (
    <Paper
      sx={{
        alignItems: "center",
        display: "flex",
        height: "100%",
        justifyContent: "center",
        width: "100%"
      }}
    >
      <Typography variant="h5" color="inherit">
        No data
      </Typography>
    </Paper>
  );
}
