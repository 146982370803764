import validateDataSeries from "./validateDataSeries";
import validateDataset from "./validateDataset";

export default function validateData(data: any) {
  if (typeof data !== "object") {
    throw new TypeError("Data is not an object");
  }

  if (!data.hasOwnProperty("title")) {
    throw new TypeError(`Data is missing property "title"`);
  }

  if (!data.hasOwnProperty("label")) {
    throw new TypeError(`Data is missing property "label"`);
  }

  if (!data.hasOwnProperty("units")) {
    throw new TypeError(`Data is missing property "units"`);
  }

  if (data.hasOwnProperty("sets")) {
    if (!Array.isArray(data.sets)) {
      throw new TypeError("The sets property is not an array");
    }
    data.sets.forEach((s: any) => {
      validateDataset(s);
    });
    return;
  }

  if (data.hasOwnProperty("series")) {
    if (!Array.isArray(data.series)) {
      throw new TypeError("The series property is not an array");
    }
    data.series.forEach((s: any) => {
      validateDataSeries(s);
    });
    return;
  }

  throw new TypeError(
    `One of the following properties is required in Data and all are missing: "series", "sets"`
  );
}
