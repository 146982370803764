import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import TextField from "@mui/material/TextField";
import React from "react";
import { preventDefaultKeyboardShortcuts } from "../interactions";

export interface Props {
  title: string;
  text: string;
  label: string;
  initialValue?: string;
  open: boolean;
  handleClose: () => void;
  handleSubmit: (value: string) => void;
}

export default function StringInputDialog({
  title,
  text,
  label,
  initialValue = "",
  open,
  handleClose,
  handleSubmit
}: Props) {
  const inputRef = React.useRef<HTMLInputElement>(null);

  const onSubmit = React.useCallback(() => {
    if (inputRef.current?.value) {
      handleSubmit(inputRef.current.value);
    }
  }, [handleSubmit]);

  const onKeyDown = React.useCallback(
    (e: React.KeyboardEvent) => {
      e.stopPropagation();
      preventDefaultKeyboardShortcuts(e);
      if (e.key === "Enter") {
        e.preventDefault();
        onSubmit();
        return;
      }
    },
    [onSubmit]
  );

  return (
    <Dialog
      open={open}
      onClose={handleClose}
      onKeyDown={onKeyDown}
      onKeyUp={(e: React.KeyboardEvent) => e.stopPropagation()}
      aria-labelledby="form-dialog-title"
    >
      <DialogTitle id="form-dialog-title">{title}</DialogTitle>
      <DialogContent>
        <DialogContentText>{text}</DialogContentText>
        <TextField
          inputRef={inputRef}
          id="text-input"
          type="text"
          label={label}
          multiline={true}
          defaultValue={initialValue}
          variant="standard"
        />
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose} color="inherit">
          Cancel
        </Button>
        <Button onClick={onSubmit} color="success">
          Submit
        </Button>
      </DialogActions>
    </Dialog>
  );
}
