import { Paper, Typography } from "@mui/material";
import { useTheme } from "@mui/material/styles";
import React from "react";
import {
  CartesianGrid,
  Label,
  Legend,
  LegendType,
  Line,
  LineChart,
  ResponsiveContainer,
  Tooltip,
  XAxis,
  YAxis
} from "recharts";
import { getColourAtIndex } from "../../theme";
import CustomDot from "./CustomDot";
import getShapeAtIndex from "./getShapeAtIndex";

export interface Point {
  x: number;
  [key: string]: number;
}

export interface Props {
  points: Point[];
  label?: string;
  units?: string;
}

export default function MultilineChart({ points, label, units }: Props) {
  const theme = useTheme();

  const { x, ...rest } = points.length > 0 ? points[0] : { x: -1 };
  const ticks = points.map(p => p.x);
  const labels = Object.keys(rest);

  const CustomTooltip = ({ active, payload }: any) => {
    if (active && payload && payload.length) {
      return (
        <Paper>
          <Typography
            sx={{
              display: "flex",
              flexDirection: "column",
              padding: "5px"
            }}
            variant="caption"
          >
            {payload.map((p: any) => (
              <span key={p.name}>{`${p.name}: ${p.value} ${units}`}</span>
            ))}
          </Typography>
        </Paper>
      );
    }
    return null;
  };

  const lines = React.useMemo(() => {
    return labels.map((label, index) => {
      const shape = getShapeAtIndex(index);
      const colour = getColourAtIndex(index);
      return (
        <Line
          key={label}
          type="natural"
          dataKey={label}
          stroke={colour}
          strokeWidth={2}
          legendType={shape as LegendType}
          dot={<CustomDot shape={shape} fill={colour} />}
          activeDot={
            <CustomDot
              shape={shape}
              fill="none"
              stroke={theme.palette.text.primary}
              strokeWidth={2}
            />
          }
        />
      );
    });
  }, [labels, theme.palette.text.primary]);

  let yMax = 0;
  let yMaxDataKey: string = labels[0];
  points.forEach(p => {
    labels.forEach(l => {
      if (p[l] > yMax) {
        yMax = p[l];
        yMaxDataKey = l;
      }
    });
  });

  return (
    <ResponsiveContainer>
      <LineChart
        margin={{
          top: 10,
          right: 20,
          left: 20,
          bottom: 25
        }}
        data={points}
      >
        <CartesianGrid strokeDasharray="3 3" />
        <XAxis type="number" dataKey="x" ticks={ticks}>
          <Label
            value={`Number of elements`}
            position="bottom"
            fontSize="1.2em"
            fill={theme.palette.text.primary}
          />
        </XAxis>
        <YAxis type="number" dataKey={yMaxDataKey} tick={{ fontSize: "1em" }}>
          <Label
            value={`${label} [${units}]`}
            position="left"
            fontSize="1.2em"
            angle={-90}
            fill={theme.palette.text.primary}
          />
        </YAxis>
        <Tooltip
          content={<CustomTooltip />}
          cursor={{ strokeDasharray: "3 3" }}
        />
        {lines}
        <Legend
          align="right"
          verticalAlign="top"
          height={36}
          onClick={({ dataKey }) =>
            window.dispatchEvent(
              new CustomEvent("renameset", {
                detail: { oldName: dataKey }
              })
            )
          }
        />
      </LineChart>
    </ResponsiveContainer>
  );
}
