import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import TextField from "@mui/material/TextField";
import React from "react";
import { renameSet, useAppDispatch } from "../../features";
import {
  preventDefaultKeyboardShortcuts,
  useWindowEventListener
} from "../interactions";

export default function EditSetDialog() {
  const dispatch = useAppDispatch();

  const [open, setOpen] = React.useState(false);
  const [value, setValue] = React.useState("");

  const oldName = React.useRef("");

  useWindowEventListener(
    "renameset",
    React.useCallback((e: CustomEvent) => {
      oldName.current = e.detail.oldName;
      setOpen(true);
    }, [])
  );

  const onClose = React.useCallback(() => {
    setOpen(false);
  }, []);

  const onChangeText = React.useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      setValue(e.currentTarget.value);
    },
    []
  );

  const onSubmit = React.useCallback(() => {
    setOpen(false);
    dispatch(
      renameSet({
        oldName: oldName.current,
        newName: value
      })
    );
  }, [dispatch, value]);

  const onKeyDown = React.useCallback(
    (e: React.KeyboardEvent) => {
      e.stopPropagation();
      preventDefaultKeyboardShortcuts(e);
      if (e.key === "Enter") {
        e.preventDefault();
        onSubmit();
        return;
      }
    },
    [onSubmit]
  );

  return (
    <Dialog
      open={open}
      onClose={onClose}
      onKeyDown={onKeyDown}
      onKeyUp={(e: React.KeyboardEvent) => e.stopPropagation()}
      aria-labelledby="form-dialog-title"
    >
      <DialogTitle id="form-dialog-title">{`Rename Set`}</DialogTitle>
      <DialogContent>
        <DialogContentText>
          {`Rename set "${oldName.current}" to:`}
        </DialogContentText>
        <TextField
          id="text-input"
          type="text"
          label="Set name"
          multiline={true}
          fullWidth={true}
          defaultValue={oldName.current}
          onChange={onChangeText}
          variant="standard"
        />
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose} color="inherit">
          Cancel
        </Button>
        <Button onClick={onSubmit} color="success">
          Submit
        </Button>
      </DialogActions>
    </Dialog>
  );
}
