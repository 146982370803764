export default function loadDataFromFile(
  file: File,
  callback: (data: any) => void
) {
  const reader = new FileReader();

  reader.onload = () => {
    try {
      callback(loadJSON(reader.result));
    } catch (e: any) {
      alert(
        `Cannot load data from ${file.name}.\n
        ${e.message}\n`
      );
    }
  };

  reader.readAsText(file);
}

const loadJSON = (input: string | ArrayBuffer | null) => {
  if (typeof input !== "string") {
    throw new Error(`Cannot load file. The format is not json.`);
  }

  try {
    return JSON.parse(input);
  } catch (e) {
    alert("Cannot parse file. The contents is not valid json.");
  }
};
