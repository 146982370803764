import { PaletteMode } from "@mui/material";
import { blue, blueGrey, grey, teal } from "@mui/material/colors";
import { createTheme, responsiveFontSizes } from "@mui/material/styles";

const SEToolkitColours = {
  Blue: "#093883",
  Green: "#135A00",
  Grey: "#808080",
  Purple: "#790052",
  Red: "#9E0013",
  White: "#F9F9F9",
  Yellow: "#C67E00"
};

export const getColourAtIndex = (index: number): string => {
  if (index < 0) return SEToolkitColours.Blue;

  const colourKeys = Object.keys(SEToolkitColours);
  if (index > colourKeys.length - 1) {
    index = index % colourKeys.length;
  }

  return SEToolkitColours[colourKeys[index] as keyof typeof SEToolkitColours];
};

export const SETColours = {
  elementType: {
    Data: SEToolkitColours.Yellow,
    Hardware: SEToolkitColours.Red,
    Milestone: SEToolkitColours.White,
    Service: SEToolkitColours.Purple,
    Software: SEToolkitColours.Green,
    System: SEToolkitColours.Blue,
    Undefined: SEToolkitColours.Grey
  },
  milestoneStatus: {
    Green: SEToolkitColours.Green,
    Yellow: SEToolkitColours.Yellow,
    Red: SEToolkitColours.Red,
    Grey: SEToolkitColours.Grey
  }
};

declare module "@mui/material/styles/createPalette" {
  interface Palette {
    connector: {
      hover: string;
      stroke: string;
    };
    shape: {
      fill: string;
      selected: string;
      stroke: string;
    };
  }
  interface PaletteOptions {
    connector: {
      hover: string;
      stroke: string;
    };
    shape: {
      fill: string;
      selected: string;
      stroke: string;
    };
  }
  interface TypeBackground {
    card: string;
    inner: string;
    popover: string;
  }
}

export default function createSETTheme(mode?: PaletteMode) {
  return responsiveFontSizes(
    createTheme({
      components: {
        MuiTab: {
          styleOverrides: {
            root: {
              flexDirection: "row"
            }
          }
        }
      },
      palette: {
        background: {
          card: mode === "dark" ? grey[900] : grey[100],
          inner: mode === "dark" ? grey[200] : grey[800],
          popover: mode === "dark" ? blueGrey[900] : blueGrey[100]
        },
        connector: {
          hover: mode === "dark" ? grey[300] : grey[700],
          stroke: mode === "dark" ? grey[300] : grey[700]
        },
        primary: {
          main: blue[900]
        },
        secondary: {
          main: teal.A400
        },
        shape: {
          fill: mode === "dark" ? grey[800] : grey[200],
          selected: mode === "dark" ? teal.A400 : blue[900],
          stroke: mode === "dark" ? grey[300] : grey[700]
        },
        mode
      }
    })
  );
}
