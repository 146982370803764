import SettingsIcon from "@mui/icons-material/Settings";
import FormControlLabel from "@mui/material/FormControlLabel";
import IconButton from "@mui/material/IconButton";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import { Theme } from "@mui/material/styles";
import Switch from "@mui/material/Switch";
import Tooltip from "@mui/material/Tooltip";
import useMediaQuery from "@mui/material/useMediaQuery";
import { WithStyles } from "@mui/styles";
import createStyles from "@mui/styles/createStyles";
import withStyles from "@mui/styles/withStyles";
import React from "react";

const styles = (theme: Theme) =>
  createStyles({
    root: {
      // override styles here
    },
    button: {
      margin: theme.spacing(1)
    },
    icon: {
      color: theme.palette.secondary.main
    }
  });

export interface Props extends WithStyles<typeof styles> {}

const SettingsButton = ({ classes }: Props) => {
  const [anchorEl, setAnchor] = React.useState<HTMLElement | null>(null);
  const menuOpen = Boolean(anchorEl);

  const isDarkPreferred = useMediaQuery("(prefers-color-scheme: dark)");
  const [isThemeDark, setIsThemeDark] = React.useState(
    localStorage.getItem("themeMode")
      ? localStorage.getItem("themeMode") === "dark"
      : isDarkPreferred
  );
  const onChangeThemeMode = React.useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      const themeMode = e.target.checked ? "dark" : "light";
      localStorage.setItem("themeMode", themeMode);
      window.dispatchEvent(
        new CustomEvent("setthememode", {
          detail: themeMode
        })
      );
      setIsThemeDark(e.target.checked);
    },
    []
  );

  const onClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchor(event.currentTarget);
  };

  const handleCloseMenu = () => {
    setAnchor(null);
  };

  return (
    <div className={classes.root}>
      <Tooltip
        title="Settings"
        aria-label="Settings"
        placement="bottom"
        disableFocusListener={true}
      >
        <IconButton
          className={classes.button}
          aria-label="Settings"
          onClick={onClick}
          size="large"
        >
          <SettingsIcon className={classes.icon} />
        </IconButton>
      </Tooltip>
      <Menu
        id="menu-settings"
        anchorEl={anchorEl}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "left"
        }}
        keepMounted={true}
        transformOrigin={{
          vertical: "top",
          horizontal: "right"
        }}
        open={menuOpen}
        onClose={handleCloseMenu}
      >
        <MenuItem>
          <FormControlLabel
            control={
              <Switch
                checked={isThemeDark}
                onChange={onChangeThemeMode}
                name="isThemeDark"
              />
            }
            label="Dark theme"
            labelPlacement="start"
          />
        </MenuItem>
      </Menu>
    </div>
  );
};

export default withStyles(styles, { withTheme: true })(SettingsButton);
