export default function getTimeStamp() {
  var now = new Date();

  var y = now.getFullYear();
  var m = applyTwoDigitFormat(now.getMonth() + 1);
  var d = applyTwoDigitFormat(now.getDate());

  var h = applyTwoDigitFormat(now.getHours());
  var min = applyTwoDigitFormat(now.getMinutes());

  return `${y}-${m}-${d} @ ${h}${min}`;
}

const applyTwoDigitFormat = (input: number) => {
  return input < 10 ? `0${input.toString()}` : input.toString();
};
