import { useTheme } from "@mui/material/styles";
import { bin, Bin as d3Bin } from "d3-array";
import React from "react";
import {
  Bar,
  BarChart,
  CartesianGrid,
  Label,
  LabelList,
  Legend,
  ResponsiveContainer,
  XAxis,
  YAxis
} from "recharts";
import { Series, Set } from "../../features";
import { getColourAtIndex } from "../../theme";

type Bin = d3Bin<number, number>;

export interface Point {
  x: number;
  [key: string]: number;
}

export interface Props {
  data: Set;
  reference?: string;
  label?: string;
  units?: string;
}

export default function Histogram({ data, reference, label, units }: Props) {
  const theme = useTheme();

  const { series } = data;

  const dataPoints = React.useMemo(() => {
    const points: Point[] = [];

    const lengths: number[] = [];
    const mins: number[] = [];
    const maxes: number[] = [];

    series.forEach(({ values }: Series) => {
      lengths.push(values.length);
      mins.push(Math.min(...values));
      maxes.push(Math.max(...values));
    });

    const numberOfBins = Math.floor(Math.cbrt(Math.max(...lengths)));
    const min = Math.round(Math.min(...mins));
    const max = Math.round(Math.max(...maxes));

    const histogram = bin().domain([min, max]).thresholds(numberOfBins);

    const xs: number[] = [];
    const ys: { [key: string]: number[] } = {};
    series.forEach(({ name, values }: Series) => {
      ys[name] = [];
      if (!xs.length || name === reference) {
        histogram(values).forEach((bin: Bin) => {
          xs.push(((bin.x0 || 0) + (bin.x1 || 0)) / 2);
          ys[name].push(bin.length);
        });
        return;
      }
      histogram(values).forEach((bin: Bin) => {
        ys[name].push(bin.length);
      });
    });

    xs.forEach((x, index) => {
      const point: Point = { x };
      for (const key in ys) {
        point[key] = ys[key][index];
      }
      points.push(point);
    });

    return points;
  }, [reference, series]);

  const bars = React.useMemo(
    () =>
      series.map(({ name }: Series, index) => (
        <Bar
          key={name}
          dataKey={name}
          fill={
            name === reference
              ? theme.palette.grey[800]
              : getColourAtIndex(index)
          }
        >
          <LabelList
            dataKey={name}
            position="top"
            offset={10}
            fontSize="1em"
            fontWeight="bold"
            fill={theme.palette.text.secondary}
          />
        </Bar>
      )),
    [reference, series, theme.palette.grey, theme.palette.text.secondary]
  );

  return (
    <ResponsiveContainer>
      <BarChart
        barGap={0}
        data={dataPoints}
        margin={{
          top: 10,
          right: 20,
          left: 20,
          bottom: 25
        }}
      >
        <CartesianGrid strokeDasharray="3 3" />
        <XAxis
          dataKey="x"
          tick={{ fontSize: "1em" }}
          interval="preserveStartEnd"
        >
          <Label
            value={`${label} [${units}]`}
            position="bottom"
            fill={theme.palette.text.primary}
          />
        </XAxis>
        <YAxis tick={{ fontSize: "1em" }}>
          <Label
            value="Number of measurements"
            position="insideBottomLeft"
            angle={-90}
            fill={theme.palette.text.primary}
          />
        </YAxis>
        {series.length > 1 ? (
          <Legend
            verticalAlign="top"
            height={36}
            fill={theme.palette.text.primary}
          />
        ) : null}
        {bars}
      </BarChart>
    </ResponsiveContainer>
  );
}
