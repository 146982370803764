import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { castDraft } from "immer";
import { OptionsObject, SnackbarKey } from "notistack";
import { RootState } from "../store";

export type Notification = {
  key: SnackbarKey;
  message: string;
  dismissed: boolean;
  options?: OptionsObject;
};

export type NotificationsState = Notification[];

const initialState: NotificationsState = [];

const notificationsSlice = createSlice({
  name: "model",
  initialState,
  reducers: {
    enqueueSnackbar: {
      reducer(state, action: PayloadAction<Notification>) {
        state.push(castDraft(action.payload));
      },
      prepare(message: string, options?: OptionsObject) {
        return {
          payload: {
            key: new Date().getTime() + Math.random(),
            message,
            dismissed: false,
            options
          }
        };
      }
    },
    closeSnackbar(state, action: PayloadAction<SnackbarKey | null>) {
      const key = action.payload;
      return state.map(n =>
        !action.payload || n.key === key ? { ...n, dismissed: true } : { ...n }
      );
    },
    removeSnackbar(state, action: PayloadAction<SnackbarKey>) {
      const key = action.payload;
      const index = state.findIndex(n => n.key === key);
      if (index >= 0) state.splice(index, 1);
    }
  }
});

export const selectNotifications = (state: RootState) => state.notifications;

export const { enqueueSnackbar, closeSnackbar, removeSnackbar } =
  notificationsSlice.actions;

export default notificationsSlice.reducer;
