import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import sortSeries from "../../utils/sortSeries";
import sortSets from "../../utils/sortSets";
import { RootState } from "../store";
import { Data, DataSet } from "./Data";

const createEmptyData = (): Data => ({
  title: "",
  label: "",
  units: "",
  sets: []
});

const dataSlice = createSlice({
  name: "data",
  initialState: createEmptyData(),
  reducers: {
    appendDataSet(state, action: PayloadAction<DataSet>) {
      const { title, units, series } = action.payload;
      if (units !== state.units) return;
      let count = 0;
      for (const set of state.sets) {
        if (set.name.startsWith(title)) count++;
      }
      state.sets.push({
        name: `${title}${count ? ` (${count + 1})` : ""}`,
        series
      });
      sortSets(state.sets);
    },
    clearData() {
      return createEmptyData();
    },
    loadData(state, action: PayloadAction<Data>) {
      return action.payload;
    },
    loadDataSet(state, action: PayloadAction<DataSet>) {
      const { title, label, units, series } = action.payload;
      return { title, label, units, sets: [{ name: title, series }] };
    },
    renameData(state, action: PayloadAction<string>) {
      state.title = action.payload;
    },
    renameSeries(
      state,
      action: PayloadAction<{
        setName: string;
        oldName: string;
        newName: string;
      }>
    ) {
      const { setName, oldName, newName } = action.payload;
      if (!newName || newName === oldName) return;
      const set = state.sets.find(s => s.name === setName);
      if (set) {
        const series = set.series.find(s => s.name === oldName);
        if (series) series.name = newName;
        sortSeries(set.series);
      }
    },
    renameSet(
      state,
      action: PayloadAction<{
        oldName: string;
        newName: string;
      }>
    ) {
      const { oldName, newName } = action.payload;
      if (
        !newName ||
        newName === oldName ||
        state.sets.find(s => s.name === newName)
      )
        return;
      const set = state.sets.find(s => s.name === oldName);
      if (set) set.name = newName;
      sortSets(state.sets);
    }
  }
});

export const selectData = (state: RootState) => state.data;

export const {
  appendDataSet,
  clearData,
  loadData,
  loadDataSet,
  renameData,
  renameSeries,
  renameSet
} = dataSlice.actions;

export default dataSlice.reducer;
