import { Set } from "../features";

/**
 * Sort the sets alphabetically.
 * @param sets The array of sets to be sorted.
 * @returns The original sets array sorted alphabetically.
 */
export default function sortSets(sets: Set[]): void {
  sets.sort((a, b) => {
    return a.name.localeCompare(b.name, undefined, { numeric: true });
  });
}
