import { configureStore, PreloadedState } from "@reduxjs/toolkit";
import { useDispatch } from "react-redux";
import notificationsReducer from "./notifications/notificationsSlice";
import dataReducer from "./data/dataSlice";

export const reducer = {
  notifications: notificationsReducer,
  data: dataReducer
};

const store = configureStore({
  reducer
});

export type AppDispatch = typeof store.dispatch;
export const useAppDispatch: () => AppDispatch = useDispatch;

export type RootState = ReturnType<typeof store.getState>;

export default store;

export const setupTestStore = (preloadedState?: PreloadedState<RootState>) => {
  return configureStore({
    reducer,
    preloadedState
  });
};
