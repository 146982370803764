import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import React from "react";
import { useSelector } from "react-redux";
import {
  appendDataSet,
  Data,
  DataSet,
  enqueueSnackbar,
  loadData,
  loadDataSet,
  selectData,
  useAppDispatch
} from "../../features";
import { useWindowEventListener } from "../interactions";

export default function FileImportDialog() {
  const dispatch = useAppDispatch();

  const data = useSelector(selectData);

  const [open, setOpen] = React.useState(false);
  const dataRef = React.useRef<Data | DataSet | null>(null);

  const importData = React.useCallback(
    (mode: "append" | "replace") => {
      if (!dataRef.current) return;
      if (
        mode === "append" &&
        data.units &&
        dataRef.current.units !== data.units
      ) {
        dispatch(
          enqueueSnackbar("Cannot append data. The units are not compatible.", {
            variant: "warning"
          })
        );
      }
      if (dataRef.current.hasOwnProperty("sets")) {
        // TODO
        if (mode === "append") {
          dispatch(
            enqueueSnackbar("TODO: implement append data", {
              variant: "warning"
            })
          );
          return;
        }
        dispatch(loadData(dataRef.current as Data));
        return;
      }
      if (dataRef.current.hasOwnProperty("series")) {
        const createAction = mode === "append" ? appendDataSet : loadDataSet;
        dispatch(createAction(dataRef.current as DataSet));
        return;
      }
    },
    [data.units, dispatch]
  );

  useWindowEventListener(
    "importdata",
    React.useCallback(
      (e: CustomEvent) => {
        if (e.detail) {
          dataRef.current = e.detail;
          if (data.sets.length) {
            setOpen(true);
            return;
          }
          importData("replace");
        }
      },
      [data.sets.length, importData]
    )
  );

  const handleAppend = React.useCallback(() => {
    setOpen(false);
    importData("append");
  }, [importData]);

  const handleReplace = React.useCallback(() => {
    setOpen(false);
    importData("replace");
  }, [importData]);

  return (
    <Dialog open={open} onClose={() => setOpen(false)}>
      <DialogTitle>Import Data</DialogTitle>
      <DialogContent>
        <DialogContentText>
          Append new data or replace current data?
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleAppend} color="inherit">
          Append
        </Button>
        <Button onClick={handleReplace} color="warning">
          Replace
        </Button>
      </DialogActions>
    </Dialog>
  );
}
