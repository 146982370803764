import Box from "@mui/material/Box";
import Paper from "@mui/material/Paper";
import * as React from "react";
import { useSelector } from "react-redux";
import { selectData, Series, Set } from "../../features";
import EditSetDialog from "../composition/EditSetDialog";
import MultilineChart, { Point } from "../diagrams/MultilineChart";
import NoData from "../diagrams/NoData";

export default function CompareView() {
  const data = useSelector(selectData);

  const dataPoints: Point[] = React.useMemo(() => {
    const sets = data.sets;
    const points: Point[] = [];
    sets.forEach(({ name: setName, series }: Set) => {
      series.forEach(({ name: seriesName, values }: Series) => {
        const match = seriesName.match(/\d+/g);
        const x = match ? parseInt(match[0]) : undefined;
        if (!x) return;

        const existingPoint = points.find(p => p.x === x);
        const point = existingPoint || { x };
        if (!existingPoint) {
          points.push(point);
        }

        point[setName] = Math.round(
          values.reduce((a, b) => a + b) / values.length
        );
      });
    });
    return points;
  }, [data.sets]);

  return (
    <Box
      sx={{
        display: "flex",
        height: "100%"
      }}
    >
      <Paper sx={{ flexGrow: 1 }}>
        {dataPoints.length ? (
          <MultilineChart
            points={dataPoints}
            label={data.label}
            units={data.units}
          />
        ) : (
          <NoData />
        )}
      </Paper>
      <EditSetDialog />
    </Box>
  );
}
