import { PaletteMode } from "@mui/material";
import Collapse from "@mui/material/Collapse";
import CssBaseline from "@mui/material/CssBaseline";
import { StyledEngineProvider, ThemeProvider } from "@mui/material/styles";
import useMediaQuery from "@mui/material/useMediaQuery";
import { SnackbarProvider } from "notistack";
import React from "react";
import { Provider } from "react-redux";
import store from "./features/store";
import createSETTheme from "./theme";
import AppNavBar from "./ui/AppNavBar";
import { useWindowEventListener } from "./ui/interactions";

const App = () => {
  // Use the actual viewport height
  const handleResize = () => {
    const vh = window.innerHeight * 0.01;
    document.documentElement.style.setProperty("--vh", `${vh}px`);
  };
  handleResize();
  useWindowEventListener("resize", handleResize);

  const storedThemeMode = localStorage.getItem("themeMode") as PaletteMode;
  const preferredThemeMode = useMediaQuery("(prefers-color-scheme: dark)")
    ? "dark"
    : "light";
  const [themeMode, setThemeMode] = React.useState<PaletteMode>(
    storedThemeMode || preferredThemeMode
  );
  useWindowEventListener("setthememode", (e: CustomEvent) => {
    setThemeMode(e.detail === "dark" ? "dark" : "light");
  });

  return (
    <Provider store={store}>
      <StyledEngineProvider injectFirst>
        <ThemeProvider theme={createSETTheme(themeMode)}>
          <CssBaseline />
          <SnackbarProvider
            anchorOrigin={{
              vertical: "top",
              horizontal: "right"
            }}
            maxSnack={5}
            TransitionComponent={Collapse}
          >
            <AppNavBar />
          </SnackbarProvider>
        </ThemeProvider>
      </StyledEngineProvider>
    </Provider>
  );
};

export default App;
