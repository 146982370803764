import { Series } from "../features";

/**
 * Sort the series alphabetically.
 * @param series The array of series to be sorted.
 * @returns The original series array sorted alphabetically.
 */
export default function sortSeries(series: Series[]): void {
  series.sort((a, b) => {
    return a.name.localeCompare(b.name, undefined, { numeric: true });
  });
}
