import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import React from "react";

export interface Props {
  question: string;
  explanation: string;
  open: boolean;
  handleClose: () => void;
  handleSubmit: () => void;
}

export default function AlertDialog({
  question,
  explanation,
  open,
  handleClose,
  handleSubmit
}: Props) {
  const onKeyPress = (e: React.KeyboardEvent) => {
    if (e.key === "Enter") {
      handleSubmit();
    }
  };

  return (
    <Dialog
      open={open}
      onClose={handleClose}
      aria-labelledby="alert-dialog-question"
      aria-describedby="alert-dialog-explanation"
      onKeyPress={onKeyPress}
    >
      <DialogTitle id="alert-dialog-question">{question}</DialogTitle>
      <DialogContent>
        <DialogContentText id="alert-dialog-explanation">
          {explanation}
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose} color="inherit">
          No
        </Button>
        <Button onClick={handleSubmit} color="warning">
          Yes
        </Button>
      </DialogActions>
    </Dialog>
  );
}
