import HelpIcon from "@mui/icons-material/Help";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import Divider from "@mui/material/Divider";
import IconButton from "@mui/material/IconButton";
import Link from "@mui/material/Link";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import { useTheme } from "@mui/material/styles";
import Tooltip from "@mui/material/Tooltip";
import React from "react";

export default function HelpButton() {
  const theme = useTheme();

  const [open, setOpen] = React.useState(false);

  const handleClick = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const linkColour = theme.palette.mode === "dark" ? "secondary" : "primary";

  return (
    <>
      <Tooltip
        title="Help"
        aria-label="Help"
        placement="bottom"
        disableFocusListener={true}
      >
        <IconButton
          color="inherit"
          aria-label="help"
          size="medium"
          onClick={handleClick}
        >
          <HelpIcon />
        </IconButton>
      </Tooltip>
      <Dialog open={open} onClose={handleClose}>
        <DialogTitle>Data Format</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Data files must be in JSON format and must always contains the
            following root level properties:
            <List dense={true}>
              <ListItem>
                <b>• title</b>: the title of the data
              </ListItem>
              <ListItem>
                <b>• label</b>: a label to use when plotting the data
              </ListItem>
              <ListItem>
                <b>• units</b>: the units of the data
              </ListItem>
              <ListItem>
                <b>• sets</b>: an array of data sets
              </ListItem>
            </List>
            All data points in a single file must have the same units. <br />
            <Divider sx={{ marginBottom: 1, marginTop: 1 }} />A data set is an
            object containing the following properties:
            <List dense={true}>
              <ListItem>
                <b>• name</b>: the name of the data set
              </ListItem>
              <ListItem>
                <b>• series</b>: an array of data series
              </ListItem>
            </List>
            <Divider sx={{ marginBottom: 1, marginTop: 1 }} />A data series is
            an object containing the following properties:
            <List dense={true}>
              <ListItem>
                <b>• name</b>: the name of the data series
              </ListItem>
              <ListItem>
                <b>• values</b>: an array of numbers
              </ListItem>
            </List>
            <Divider sx={{ marginBottom: 1, marginTop: 1 }} /> Sample data:
            <List dense={true}>
              <ListItem>
                <Link href="#" color={linkColour}>
                  Single Data Set
                </Link>
              </ListItem>
              <ListItem>
                <Link href="#" color={linkColour}>
                  Multi Data Set
                </Link>
              </ListItem>
            </List>
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color="success">
            Dismiss
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
}
