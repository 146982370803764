export default function validateDataSeries(series: any) {
  if (typeof series !== "object") {
    throw new TypeError("Series is not an object");
  }

  if (!series.hasOwnProperty("name")) {
    throw new TypeError(`Series is missing property "name"`);
  }

  if (!series.hasOwnProperty("values")) {
    throw new TypeError(`Series is missing property "values"`);
  }

  if (!Array.isArray(series.values)) {
    throw new TypeError("The values property is not an array");
  }
}
