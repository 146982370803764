import Box from "@mui/material/Box";
import FormControl from "@mui/material/FormControl";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import Paper from "@mui/material/Paper";
import Select, { SelectChangeEvent } from "@mui/material/Select";
import Stack from "@mui/material/Stack";
import Toolbar from "@mui/material/Toolbar";
import React from "react";
import { useSelector } from "react-redux";
import { selectData } from "../../features";
import EditSeriesDialog from "../composition/EditSeriesDialog";
import Histogram from "../diagrams/Histogram";
import IterationScatter from "../diagrams/IterationScatter";
import NoData from "../diagrams/NoData";

export default function BenchmarkView() {
  const data = useSelector(selectData);

  const seriesNames = data.sets.map(s => s.name);
  const [selectedSet, setSelectedSet] = React.useState("");

  if (selectedSet && !seriesNames.includes(selectedSet)) {
    setSelectedSet("");
  }
  React.useEffect(() => {
    if (!selectedSet && seriesNames.length) {
      setSelectedSet(seriesNames[0]);
    }
  }, [selectedSet, seriesNames]);

  const selectMenuItems = seriesNames.length ? (
    seriesNames.map(key => (
      <MenuItem key={key} value={key}>
        {key}
      </MenuItem>
    ))
  ) : (
    <MenuItem value="" disabled={true}>
      No data
    </MenuItem>
  );

  const onChange = React.useCallback((event: SelectChangeEvent) => {
    setSelectedSet(event.target.value as string);
  }, []);

  const dataset = data.sets.find(s => s.name === selectedSet);

  return (
    <Box
      sx={{
        display: "flex",
        height: "100%"
      }}
    >
      <Stack flexGrow={1} spacing={1}>
        <Toolbar
          sx={{
            alignSelf: "center",
            padding: 0.5
          }}
          disableGutters
          variant="dense"
        >
          <Box sx={{ minWidth: 200 }}>
            <FormControl fullWidth={true} disabled={seriesNames.length <= 1}>
              <InputLabel id="set-select-label">Data Set</InputLabel>
              <Select
                labelId="set-select"
                id="set-select"
                value={selectedSet}
                label="Data Set"
                onChange={onChange}
              >
                {selectMenuItems}
              </Select>
            </FormControl>
          </Box>
        </Toolbar>
        <Paper sx={{ flexGrow: 1 }}>
          {dataset ? (
            <IterationScatter
              data={dataset}
              label={data.label}
              units={data.units}
            />
          ) : (
            <NoData />
          )}
        </Paper>
        <Paper sx={{ flexGrow: 1 }}>
          {dataset ? (
            <Histogram data={dataset} label={data.label} units={data.units} />
          ) : (
            <NoData />
          )}
        </Paper>
      </Stack>
      <EditSeriesDialog />
    </Box>
  );
}
