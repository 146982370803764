import SaveIcon from "@mui/icons-material/SaveAlt";
import Box from "@mui/material/Box";
import IconButton from "@mui/material/IconButton";
import { useTheme } from "@mui/material/styles";
import Tooltip from "@mui/material/Tooltip";
import React from "react";
import { useSelector } from "react-redux";
import { Data, selectData } from "../../features";
import getTimeStamp from "../../utils/getTimeStamp";
import { saveStringToFile } from "../../utils/saveToFile";
import StringInputDialog from "../composition/StringInputDialog";
import { useWindowEventListener } from "../interactions";
import exportDataAsJsonString from "./exportDataAsJsonString";

export default function FileDownloadButton() {
  const theme = useTheme();

  const data: Data = useSelector(selectData);

  const [dialogOpen, setDialogOpen] = React.useState(false);

  useWindowEventListener("savedata", () => {
    saveStringToFile(
      exportDataAsJsonString(data),
      `${data.title} ${getTimeStamp()}.json`,
      "application/json"
    );
  });

  const onClick = () => {
    setDialogOpen(true);
  };

  const handleCloseDialog = () => {
    setDialogOpen(false);
  };

  const onSubmit = (fileName: string) => {
    setDialogOpen(false);
    saveStringToFile(
      exportDataAsJsonString(data),
      `${fileName}.json`,
      "application/json"
    );
  };

  return (
    <Box>
      <Tooltip
        title="Download (ctrl+s)"
        aria-label="Download (ctrl+s)"
        placement="bottom"
        disableFocusListener={true}
      >
        <IconButton
          sx={{
            margin: theme.spacing(1)
          }}
          aria-label="Download"
          onClick={onClick}
          size="large"
        >
          <SaveIcon
            sx={{
              color: theme.palette.secondary.main
            }}
          />
        </IconButton>
      </Tooltip>
      <StringInputDialog
        title={`Download data in JSON format`}
        text="Please enter a file name."
        label="File name"
        initialValue={data.title}
        open={dialogOpen}
        handleClose={handleCloseDialog}
        handleSubmit={onSubmit}
      />
    </Box>
  );
}
