import AppBar from "@mui/material/AppBar";
import Box from "@mui/material/Box";
import { useTheme } from "@mui/material/styles";
import Tab from "@mui/material/Tab";
import Tabs from "@mui/material/Tabs";
import React from "react";
import { ErrorBoundary } from "react-error-boundary";
import {
  Link as RouterLink,
  Navigate,
  Route,
  Routes,
  useLocation
} from "react-router-dom";
import ErrorFallback from "./ErrorFallback";
import BenchmarkView from "./views/BenchmarkView";
import CompareView from "./views/CompareView";

export default function TabBar() {
  const theme = useTheme();

  const tabRoutes = {
    Benchmark: {
      label: "Benchmark",
      path: "/benchmark",
      element: <BenchmarkView />
    },
    Compare: {
      label: "Compare",
      path: "/compare",
      element: <CompareView />
    }
  };

  const tabs = Object.values(tabRoutes).map(({ label, path }) => (
    <Tab
      key={path}
      label={label}
      component={RouterLink}
      to={path}
      value={path}
    />
  ));

  const location = useLocation();
  const matches = location.pathname.match(/(\/\w+)/i);
  const match = matches && matches[0];
  const isValid =
    match &&
    Object.values(tabRoutes)
      .map(r => r.path)
      .includes(match);
  const currentTab = isValid ? match : tabRoutes.Compare.path;

  const handleChange = React.useCallback(
    (e: React.SyntheticEvent, value: string) => {
      window.dispatchEvent(new CustomEvent("changeview", { detail: value }));
    },
    []
  );

  const routes = Object.values(tabRoutes).map(({ path, element }) => (
    <Route key={path} path={`${path}/*`} element={element} />
  ));

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        flexGrow: 1
      }}
    >
      <AppBar position="static">
        <Tabs
          sx={{ backgroundColor: "primary.main" }}
          value={currentTab}
          variant="scrollable"
          indicatorColor="secondary"
          textColor="inherit"
          onChange={handleChange}
        >
          {tabs}
        </Tabs>
      </AppBar>
      <Box sx={{ position: "relative", flexGrow: 1 }}>
        <ErrorBoundary
          FallbackComponent={ErrorFallback}
          resetKeys={[currentTab]}
        >
          <Box
            sx={{
              bottom: 0,
              left: 0,
              padding: theme.spacing(1),
              position: "absolute",
              right: 0,
              top: 0
            }}
          >
            <Routes>
              <Route index={true} element={<Navigate to="compare" />} />
              {routes}
            </Routes>
          </Box>
        </ErrorBoundary>
      </Box>
    </Box>
  );
}
