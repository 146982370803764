import "@fontsource/roboto";
import React from "react";
import { createRoot } from "react-dom/client";
import App from "./App";
import "./index.css";
import reportWebVitals from "./reportWebVitals";

const container = document.getElementById("root");
const root = createRoot(container!);
root.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();

// Signature
console.log(
  "%cAn %cSE-Toolkit %cApp (https://se-toolkit.app)",
  "color:#F9F9F9; font-size: 12px",
  "color:#093883; font-size: 12px; font-weight: bold",
  "color:#F9F9F9; font-size: 12px"
);
