import validateDataSeries from "./validateDataSeries";

export default function validateDataset(dataset: any) {
  if (typeof dataset !== "object") {
    throw new TypeError("Dataset is not an object");
  }

  if (!dataset.hasOwnProperty("name")) {
    throw new TypeError(`Dataset is missing property "name"`);
  }

  if (!dataset.hasOwnProperty("series")) {
    throw new TypeError(`Dataset is missing property "series"`);
  }

  if (!Array.isArray(dataset.series)) {
    throw new TypeError("The series property is not an array");
  }

  dataset.series.forEach((s: any) => {
    validateDataSeries(s);
  });
}
