import AppBar from "@mui/material/AppBar";
import Box from "@mui/material/Box";
import IconButton from "@mui/material/IconButton";
import Link from "@mui/material/Link";
import Paper from "@mui/material/Paper";
import { useTheme } from "@mui/material/styles";
import Toolbar from "@mui/material/Toolbar";
import Typography from "@mui/material/Typography";
import { BrowserRouter } from "react-router-dom";
import ClearDataButton from "./composition/ClearDataButton";
import Title from "./composition/Title";
import HelpButton from "./HelpButton";
import FileDownloadButton from "./inputoutput/FileDownloadButton";
import FileImportDialog from "./inputoutput/FileImportDialog";
import useFileDropHandler from "./inputoutput/useFileDropHandler";
import { useAppKeyboardShortcuts } from "./interactions";
import logo from "./logo.png";
import Notifier from "./Notifier";
import SettingsButton from "./SettingsButton";
import TabBar from "./TabBar";

export default function AppNavBar() {
  const theme = useTheme();

  useAppKeyboardShortcuts();

  const { onDragOver, onDrop } = useFileDropHandler();

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        height: "calc(var(--vh, 1vh) * 100)"
      }}
    >
      <Notifier />
      <AppBar position="static">
        <Toolbar sx={{ backgroundColor: "primary.dark" }}>
          <IconButton
            href="https://se-toolkit.app"
            rel="noopener"
            target="_blank"
            sx={{
              marginLeft: -1.5,
              marginRight: 1.5
            }}
            aria-label="se-toolkit.app"
            size="large"
          >
            <img src={logo} alt="SE-Toolkit icon" />
          </IconButton>
          <Box
            sx={{
              flexGrow: 1,
              textAlign: "left"
            }}
          >
            <Typography variant="h6" sx={{ userSelect: "none" }}>
              Systems Engineering Toolkit Graphs
            </Typography>
            <Typography variant="subtitle1" sx={{ userSelect: "none" }}>
              Simple. Effective. Tools.
            </Typography>
          </Box>
          <Box
            sx={{
              flexGrow: 1,
              textAlign: "left"
            }}
          >
            <Title />
          </Box>
          <FileDownloadButton />
          <ClearDataButton />
          <SettingsButton />
          <HelpButton />
        </Toolbar>
      </AppBar>
      <Box
        sx={{
          display: "flex",
          flexGrow: 1
        }}
      >
        <Box
          sx={{
            display: "flex",
            flexGrow: 1,
            position: "relative"
          }}
          onDrop={onDrop}
          onDragOver={onDragOver}
        >
          <BrowserRouter>
            <TabBar />
          </BrowserRouter>
        </Box>
        <FileImportDialog />
      </Box>
      <Paper>
        <Typography
          sx={{
            backgroundColor: theme.palette.primary.dark,
            color: "rgba(255, 255, 255, 0.7)",
            padding: 0.5,
            textAlign: "center",
            userSelect: "none",
            whiteSpace: "nowrap"
          }}
          variant="subtitle2"
        >
          Copyright © 2019-{new Date().getFullYear()} Tristan Martindale. Email:{" "}
          <Link href="mailto: hello@se-toolkit.app" color="secondary">
            hello@se-toolkit.app
          </Link>
        </Typography>
      </Paper>
    </Box>
  );
}
