import { Data } from "../../features";

/**
 * Export the data to a JSON string
 * @param data The data to be exported.
 * @returns A JSON string representing the data.
 */
export default function exportDataAsJsonString(data: Data): string {
  return JSON.stringify(data, null, 2);
}
