import * as React from "react";
import { enqueueSnackbar, useAppDispatch } from "../../features";
import loadDataFromFile from "../../utils/loadDataFromFile";
import validateData from "../../validators/validateData";

export default function useFileDropHandler() {
  const dispatch = useAppDispatch();

  const fileRef = React.useRef<File | null>(null);

  const handleFileInput = React.useCallback(
    (file: File) =>
      loadDataFromFile(file, (data: any) => {
        try {
          validateData(data);
          window.dispatchEvent(new CustomEvent("importdata", { detail: data }));
        } catch (e: any) {
          dispatch(enqueueSnackbar(e.message, { variant: "warning" }));
        }
      }),
    [dispatch]
  );

  const onDragOver = (event: React.DragEvent<HTMLDivElement>) => {
    event.preventDefault();
  };

  const onDrop = (e: React.DragEvent<HTMLDivElement>) => {
    e.preventDefault();

    if (e.dataTransfer.items && e.dataTransfer.items.length > 0) {
      // Use DataTransferItemList interface to access the file(s)
      // If dropped items aren't files, reject them
      // Only handle the first file
      const item = e.dataTransfer.items[0];
      if (item.kind === "file") {
        fileRef.current = item.getAsFile();
      }
    } else {
      // Use DataTransfer interface to access the file(s)
      fileRef.current = e.dataTransfer.files[0];
    }

    if (fileRef.current) {
      handleFileInput(fileRef.current);
    }
  };

  return { onDragOver, onDrop };
}
