import {
  symbol,
  symbolCircle,
  symbolCross,
  symbolDiamond,
  symbolSquare,
  symbolStar,
  symbolTriangle,
  symbolWye
} from "d3-shape";
import React from "react";
import { SEToolkitShapes } from "./getShapeAtIndex";

export interface Props {
  cx?: number;
  cy?: number;
  shape?: string;
  size?: number;
  fill?: string;
  stroke?: string;
  strokeWidth?: number;
}

export default function CustomDot({
  cx = 0,
  cy = 0,
  shape,
  size = 100,
  fill,
  stroke,
  strokeWidth
}: Props) {
  const symbolGenerator = symbol();
  switch (shape) {
    case SEToolkitShapes.circle:
      symbolGenerator.type(symbolCircle);
      break;
    case SEToolkitShapes.cross:
      symbolGenerator.type(symbolCross);
      break;
    case SEToolkitShapes.diamond:
      symbolGenerator.type(symbolDiamond);
      break;
    case SEToolkitShapes.square:
      symbolGenerator.type(symbolSquare);
      break;
    case SEToolkitShapes.star:
      symbolGenerator.type(symbolStar);
      break;
    case SEToolkitShapes.triangle:
      symbolGenerator.type(symbolTriangle);
      break;
    case SEToolkitShapes.wye:
      symbolGenerator.type(symbolWye);
      break;
    default:
      symbolGenerator.type(symbolCircle);
      break;
  }
  symbolGenerator.size(size);

  return (
    <svg x={cx - 10} y={cy - 10} width={20} height={20}>
      <path
        transform="translate(10 10)"
        fill={fill}
        stroke={stroke}
        strokeWidth={strokeWidth}
        d={`${symbolGenerator()}`}
      />
    </svg>
  );
}
