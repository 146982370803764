import Container from "@mui/material/Container";
import Typography from "@mui/material/Typography";

export interface Props {
  error?: Error;
}

export default function ErrorFallback({ error }: Props) {
  return (
    <Container
      sx={{
        display: "flex",
        height: "100%",
        alignItems: "center"
      }}
    >
      <Typography variant="h1">
        Terribly sorry, but something went wrong.
      </Typography>
    </Container>
  );
}
