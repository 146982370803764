import { Paper, Typography, useTheme } from "@mui/material";
import * as React from "react";
import {
  CartesianGrid,
  Label,
  Legend,
  ReferenceLine,
  ResponsiveContainer,
  Scatter,
  ScatterChart,
  Tooltip,
  XAxis,
  YAxis
} from "recharts";
import { Series, Set } from "../../features";
import { getColourAtIndex } from "../../theme";

export interface Point {
  x: number;
  [key: string]: number;
}

export interface Props {
  data: Set;
  reference?: string;
  label?: string;
  units?: string;
}

export default function IterationScatter({
  data,
  reference,
  label,
  units
}: Props) {
  const theme = useTheme();

  const { series } = data;

  const referenceSeries: Series | undefined = series.length
    ? series[series.length - 1]
    : undefined;

  const dataPoints = React.useMemo(
    () =>
      referenceSeries?.values.map((value: number, index: number) => ({
        x: index,
        y: value
      })) || [],
    [referenceSeries?.values]
  );

  const lines = React.useMemo(
    () =>
      series.map(({ name, values }: Series, index: number) => {
        const colour =
          name === reference
            ? theme.palette.grey[800]
            : getColourAtIndex(index);

        const mean = Math.round(values.reduce((a, b) => a + b) / values.length);

        return (
          <ReferenceLine
            key={name}
            y={mean}
            stroke={colour}
            strokeWidth={2}
            strokeDasharray="3 3"
          >
            <Label
              value={`${name} (${mean} ${units})`}
              position="top"
              offset={10}
              fontSize="1em"
              fontWeight="bold"
              fill={colour}
              onClick={() =>
                window.dispatchEvent(
                  new CustomEvent("renameseries", {
                    detail: { setName: data.name, oldName: name }
                  })
                )
              }
            />
          </ReferenceLine>
        );
      }),
    [data.name, reference, series, theme.palette.grey, units]
  );

  const CustomTooltip = ({ active, payload }: any) => {
    if (active && payload && payload.length) {
      return (
        <Paper>
          <Typography
            sx={{
              padding: "5px"
            }}
            variant="caption"
          >
            {`${payload[1].value} ${units}`}
          </Typography>
        </Paper>
      );
    }
    return null;
  };

  return (
    <ResponsiveContainer>
      <ScatterChart
        margin={{
          top: 15,
          right: 20,
          left: 20,
          bottom: 10
        }}
      >
        <CartesianGrid strokeDasharray="3 3" />
        <XAxis type="number" dataKey="x" hide={true} />
        <YAxis type="number" dataKey="y" tick={{ fontSize: "1em" }}>
          <Label
            value={`${label} [${units}]`}
            position="insideBottomLeft"
            angle={-90}
            fill={theme.palette.text.primary}
          />
        </YAxis>
        <Tooltip
          content={<CustomTooltip />}
          cursor={{ strokeDasharray: "3 3" }}
        />
        {referenceSeries ? (
          <Scatter
            name={referenceSeries.name}
            data={dataPoints}
            fill={theme.palette.text.secondary}
            opacity={0.1}
          />
        ) : null}
        {lines}
        <Legend align="right" verticalAlign="top" height={36} />
      </ScatterChart>
    </ResponsiveContainer>
  );
}
