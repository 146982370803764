import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import React from "react";
import { useSelector } from "react-redux";
import { renameData, RootState, useAppDispatch } from "../../features";
import StringInputDialog from "./StringInputDialog";

export default function Title() {
  const dispatch = useAppDispatch();

  const dataTitle: string = useSelector((store: RootState) => store.data.title);

  React.useEffect(() => {
    document.title = `${dataTitle ? dataTitle : "New Data"}`;
  }, [dataTitle]);

  const [open, setOpen] = React.useState(false);

  const handleSubmit = React.useCallback(
    (value: string) => {
      setOpen(false);
      if (value === dataTitle) return;
      dispatch(renameData(value));
    },
    [dispatch, dataTitle]
  );

  return (
    <>
      <Button
        sx={{
          textTransform: "none"
        }}
        color="inherit"
      >
        <Typography
          variant="h4"
          onClick={() => {
            setOpen(true);
          }}
        >
          {dataTitle}
        </Typography>
      </Button>
      <StringInputDialog
        title="Rename Data"
        text="Please enter a new name for the data."
        label="New data name"
        initialValue={dataTitle}
        open={open}
        handleClose={() => {
          setOpen(false);
        }}
        handleSubmit={handleSubmit}
      />
    </>
  );
}
