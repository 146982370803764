import useWindowEventListener from "./useWindowEventListener";

export default function useAppKeyboardShortcuts() {
  // shortcuts triggered on keydown
  useWindowEventListener("keydown", (e: KeyboardEvent) => {
    if (e.key === "s" && (e.ctrlKey || e.metaKey)) {
      e.preventDefault(); // prevent browser's standard save webpage response
      return;
    }
  });

  // shortcuts triggered on keyup
  useWindowEventListener("keyup", (e: KeyboardEvent) => {
    if (e.key === "s" && (e.ctrlKey || e.metaKey)) {
      window.dispatchEvent(new CustomEvent("savedata"));
      return;
    }
  });
}
