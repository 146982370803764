export function saveStringToFile(
  input: string,
  filename: string,
  type: string
) {
  saveBlobToFile(new Blob([input], { type }), filename);
}

export function saveBlobToFile(blob: Blob, filename: string) {
  if (!isSaveViaLinkSupported()) {
    const reader = new FileReader();
    reader.onload = function () {
      if (reader.result && typeof reader.result === "string") {
        window.location.href = reader.result;
      }
      alert(`Download "document". Open the Files app, go to
      Locations -> "On my <iOS device>", open the Chrome folder, 
      and rename the file ${filename}.`);
    };
    reader.readAsDataURL(blob);
  } else {
    const blobURL = URL.createObjectURL(blob);
    const tempLink = document.createElement("a");
    tempLink.style.display = "none";
    tempLink.href = blobURL;
    tempLink.setAttribute("download", filename);
    // Safari thinks _blank anchor are pop ups. We only want to set _blank
    // target if the browser does not support the HTML5 download attribute.
    // This allows you to download files in desktop safari if pop up blocking
    // is enabled.
    if (typeof tempLink.download === "undefined") {
      tempLink.setAttribute("target", "_blank");
    }
    tempLink.click();
    tempLink.remove();
    URL.revokeObjectURL(blobURL);
  }
}

function isSaveViaLinkSupported() {
  const isAppleDevice = navigator.userAgent.match(/iPad|iPhone|iPod|Mac/i);
  return !isAppleDevice || !navigator.userAgent.match(/CriOS|FxiOS/i);
}
