export enum SEToolkitShapes {
  circle = "circle",
  cross = "cross",
  diamond = "diamond",
  square = "square",
  star = "star",
  triangle = "triangle",
  wye = "wye"
}

export default function getShapeAtIndex(index: number): string {
  if (index < 0) return SEToolkitShapes.circle;

  const shapeKeys = Object.keys(SEToolkitShapes);
  if (index > shapeKeys.length - 1) {
    index = index % shapeKeys.length;
  }

  return SEToolkitShapes[shapeKeys[index] as keyof typeof SEToolkitShapes];
}
